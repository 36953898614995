
.eCommerce-container {
    height: 100%;
    position: relative;
    .eCommerce-main {
        height: 100%;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .line-1 {
            display: flex;
            margin: 20px;
        }
    }
}
.tab-top {
    position: relative;
    margin: 0 20px;
    .back-btn {
        position: absolute;
        right: 0;
        top: 0;
    }
}
.data-item {
    padding: 34px 20px 40px;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, .05);
    border-radius: 10px;
    background: #fff;
}
.tab-title {
    padding-left: 15px;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: -2px;
        left: 0;
        width: 5px;
        height: 20px;
        background: #2DC079;
        border-radius: 3px;
    }
    .popover-title {
        font-size: 18px;
        line-height: 1;
        display: inline-block;
    }
}
.data-content {
    height: 300px;
    width: 100%;
    margin-top: 30px;
}
.sort-num {
    display: inline-block;
    background: #E7F6EF;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    line-height: 24px;
    text-align: center;
}
.sort-img {
    display: inline-block;
    img {
        display: block;
    }
}
.deal-box {
    align-items: center;
    .el-progress {
        width: 1%;
        flex: 1;
        margin-left: 15px;
        margin-right: 20px;
    }
}
.no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    .no-img {
        max-width: 200px;
    }
    .text {
        color: #999;
        margin-top: 12px;
        padding: 0 20px;
    }
}
.el-table {
    ::v-deep .el-table__row.statistics-warning-row {
        background: #F9FFFC;
    }
}
